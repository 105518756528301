// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
//import useJwt from '@src/auth/jwt/useJwt'

//const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}


export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    pageData:1,
    chartTitle:''
  },
  reducers: {
    handleLogin: (state, action) => {
    state.userData = action.payload
    //   if (action.payload) navigate('/home')
    //   console.log('pay load', action.payload && action.payload)
      //const userData = {email:'', password:''}
     // state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
     // state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
    //  localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
     // localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      //console.log('hiooooo') 
      state.userData = {}
     // state[config.storageTokenKeyName] = null
     // state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
     // localStorage.removeItem(config.storageTokenKeyName)
     // localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    handlePage: (state, action) => {
   //   console.log('hiooooo',  action.payload) 
      state.pageData = action.payload
      localStorage.setItem('pageData', JSON.stringify(action.payload))
    },
    handleTitle: (state, action) => {
      state.chartTitle = action.payload
    //  console.log('hiooooo', action.payload)
    }

  }
})
 
export const { handleLogin, handleLogout, handlePage, handleTitle } = authSlice.actions

export default authSlice.reducer
